.skills {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    color: white;
    text-align: left;
    font-weight: 800;
    background-color: #303030;
}

.skills-lines {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    margin-top: 2.5em
}

.skill-card {
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;

    margin: 0.8em;
    height: 2.6em;
    width: 10em;
    border-radius: 0.5em;
    color: #303030;
    background-color: white;

    font-size: 1.8em;
    font-weight: 530;

    border-style: solid;
    border-width: 0.12em;
}

.card-border-be {
    border-color: #FF6600;
}

.card-border-fe {
    border-color: #FF6600;
}

.img-skills-logo-l {
    margin-right: 0.4em;
    margin-left: 0.4em;
    height: 2em;
}

.img-skills-logo-m {
    margin-right: 0.4em;
    margin-left: 0.3em;
    height: 1.8em;
}

.img-skills-logo-s {
    margin-right: 0.4em;
    margin-left: 0.3em;
    height: 1.6em;
}

@media only screen and (max-width: 1023px) {
    .skills-lines {
        margin-top: 1.5em;
    }

    .skill-card {
        margin: 0.5em;
    }
}