.header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em 0;
    background-color: #303030;
    position: fixed;
    width: 100%;
    height: 7vh;
    top: 0;
    z-index: 1000;
    box-sizing: border-box;
    font-size: 1.4em;
}

.header-item {
    color: white;
    cursor: pointer;
    text-decoration: none;
    padding: 0.3em 0.3em;
    margin: 0 0.7em;
}

.header-item.active {
    color: #FF6600;
}

.name-logo {
    display: none;
    font-weight: 1000;
    font-size: 1.2rem;
    padding-left: 1em;
    color: white;
}

.navbar-mobile {
    visibility: collapse;
}

.navbar-menu {
    position: fixed;
    right: 0;
    background-color: #303030;
    width: 100%;
    text-align: center;
    padding: 0;
}

.navbar {
    background-color: transparent;
    align-content: center;
}

.navbar-burger {
    color: white;
}

@media only screen and (max-width: 1023px) {
    .header {
        justify-content: space-between;
    }

    .name-logo {
        display: flex;
    }

    .navbar-menu.is-active {
        display: block;
        height: 100vh;
        right: 0;
    }

    .navbar-end {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 80vh;
    }

    .navbar-item {
        font-size: 1.5em;
        color: white;
        width: fit-content;
        text-transform: uppercase;
    }

    .navbar-item.active {
        color: #FF6600;
        font-weight: 700;
    }

    .navbar-mobile {
        visibility: visible;
    }

    .header-item {
        display: none;
    }
}


