.experience {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    background-image: linear-gradient(180deg, rgba(48, 48, 48, 1) -100%, rgba(255, 255, 255, 1) 23%, rgba(255, 255, 255, 1) 90%, rgba(48, 48, 48, 1) 200%);
    text-align: left;
    font-weight: 800;
}

.img-czm-logo {
    margin-right: 1em;
    padding: 0.5em;
    height: 2em;
    border-radius: 0.4em;
    background-color: #1FAEE9;
    cursor: pointer;
}

.img-tipsport-logo {
    margin-right: 1em;
    /*padding: 0.2em;*/
    height: 2em;
    /*width: 6em;*/
    border-radius: 0.4em;
    cursor: pointer;
}


@media only screen and (max-width: 1023px) {
    .experience {
        background-color: white;
        background-image: none;
    }
}