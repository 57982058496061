.app {
    text-align: center;
    font-family: 'Roboto', monospace;
}

.my-section {
    min-height: 100vh;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 7vh;
    padding-bottom: 5vh;
    padding-left: 10%;
    padding-right: 10%;
    color: #303030;
}

.section-heading {
    font-size: 3.8em;
}

.font-orange {
    color: #FF6600;
}

.font-white {
    color: white;
}

.font-grey {
    color: #303030;
}

.section-card {
    width: 100%;
}

.section-card-first {
    margin-top: 3em;
}

.section-card-next {
    margin-top: 3em;
}

.section-card-last {
    margin-bottom: 1em;
}

.section-card-header {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    background-color: #303030;
    border-top-left-radius: 1.2em;
    border-top-right-radius: 1.2em;
    color: white;
    font-size: 1.6em;
    font-weight: 800;
    padding: 0.6em 1em;
}

.section-card-header-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    margin-right: 0.5em;
}

.section-card-header-tag {
    margin-left: 0.5em;
    text-align: right;
    font-weight: 100;
    font-size: 0.9em;
    color: rgb(255, 255, 255, 0.9);
}

.section-card-content {
    background-color: #e7e7e7;
    border-bottom-left-radius: 1.2em;
    border-bottom-right-radius: 1.2em;
    color: #555555;
    font-weight: 400;
    font-size: 1.25em;
    padding: 0.7em 1em 0.4em 1em;
}

.section-content-text {
    padding-top: 0.8em;
}

.info-under-heading {
    font-size: 1.4em;
    text-align: center;
    width: 60%;
    font-weight: 400;
}

.font-grey-alpha-on-b {
    color: #C0C0C0;

}

.font-grey-alpha-on-w {
    color: #606060;
}

.not-visible {
    content-visibility: hidden;
}

.visible {
    visibility: visible;
}

.card-anim-show {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1s ease, transform 1s ease;
}

.card-anim-hide {
    opacity: 0;
    transform: translateY(-10%);
}

@media only screen and (max-width: 1023px) {
    .my-section {
        min-height: auto;
        padding-left: 1em;
        padding-right: 1em;
    }

    .section-heading {
        font-size: 2.5em;
    }

    .section-card-header {
        font-size: 1.2em;
    }

    .section-card-content {
        font-size: 1em;
    }

    .section-card-header-tag {
        font-size: 1em;
    }

    .info-under-heading {
        font-size: 1.2em;
        text-align: center;
        width: 100%;
    }

    .font-grey-alpha-on-w {
        color: #303030;
    }

    .font-grey-alpha-on-b {
        color: #C0C0C0;
    }

    .card-anim-show {
        transform: none;
        transition: opacity 1s ease;
    }

    .card-anim-hide {
        transform: none;
    }
}

@media (min-resolution: 1.25dppx) and (min-width: 1024px) {
    .app {
        font-size: 80%;
    }
}

@media (min-resolution: 1.5dppx)  and (min-width: 1024px) {
    .app {
        font-size: 67%;
    }
}

/*@media (min-resolution: 1.75dppx) and (min-width: 1024px) {*/
/*    .app {*/
/*        font-size: 57%;*/
/*    }*/
/*}*/

/*@media (min-resolution: 2dppx) and (min-width: 1024px) {*/
/*    .app {*/
/*        font-size: 50%;*/
/*    }*/
/*}*/
