.home {
    display: flex;
    justify-content: space-between;
    align-content: center;
    background-color: #303030;
    color: white;
    text-align: left;
    font-weight: 800;
    padding-bottom: 1vh;
}

.div-text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 50%;
    margin-right: 1.5em;
}

.font-hi {
    font-size: 2.5em;
}

.font-name {
    font-size: 3.5em;
}

.font-summary {
    font-size: 1.4em;
    font-weight: 400;
    color: #C0C0C0;
    margin-top: 0.5em;
}

.name {
    color: #FF6600;
}

.image {
    width: 55vh;
    border-radius: 1.2em;
    margin-top: 1em;
    margin-bottom: 1em;
}

.button-contact {
    display: block;
    margin-top: 2.5em;
    width: 14em;
    height: 4em;
    font-size: 1.2em !important;
}

.fw-750 {
    font-weight: 750;
    font-size: 1.3em;
}

@media only screen and (max-width: 1023px) {
    .home {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .div-text {
        max-width: 100%;
        margin-right: 0;
    }

    .text-mobile-justify {
        text-align: justify;
        hyphens: auto;
    }

    .font-hi {
        font-size: 2em;
    }

    .font-name {
        font-size: 2em;
    }

    .font-summary {
        font-size: 1.2em;
    }

    .button-contact {
        display: none;
        margin: 0;
        width: 0;
        height: 0;
    }

    .image {
        width: 30em;
    }
}