.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    justify-items: center;
    text-align: left;
    color: white;
    background-color: #303030;
}

.contact-heading {
    display: flex;
    flex-direction: row;
    align-content: center;
    font-weight: 800;
}

.contact-panel {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-content: center;
    width: 100%;
}

.contact-text {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.contact-info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-content: center;

    font-size: 1.4em;
    font-weight: 700;

    margin-top: 2.5em;
}

.contact-info-block {
    margin: 0.7em 0;
}

.contact-info-value {
    overflow-wrap: break-word;
    font-weight: 200;
}

a {
    color: white;
}

a:visited {
    text-decoration: none;
}

@media only screen and (max-width: 1023px) {
    .contact {
        justify-items: center;
        text-align: center;
        color: white;
        background-color: #303030;
    }

    .contact-heading {
        display: flex;
        flex-direction: column;
        align-content: center;
        width: 90%;
    }

    .contact-info {
        width: 90%;
        margin-top: 1em;
    }

    .contact-text {
        text-align: center;
        align-items: center;
    }

    .contact-info-block {
        margin: 0.7em 0;
    }

    .contact-panel {
        justify-content: center;
        flex-direction: column;
    }

    .form-contact-me {
        margin-top: 1.5em;
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 425px) {
    .form-contact-me {
        content-visibility: hidden;
        visibility: collapse;
    }
}