.form-detail {
    background-color: #eaeaea;

    padding: 1.5em;
    width: 40em;
    border-radius: 1.2em;
}

.form-heading {
    font-size: 2em;
    font-weight: 700;
    text-align: center;
    margin-bottom: 1em;
}

.font-form {
    font-size: 1.2em;
}

.field {
    margin-bottom: 1.5em !important;
}

.button-submit {
    background-color: #303030;
    color: white;
    margin-right: 1em;
}

.submit-result {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: start;
}

input, textarea {
    background-color: white !important;
}

@media only screen and (max-width: 1023px) {
    .form-detail {
        max-width: 90%;
        --bulma-control-size: 1.2em;
    }

    .form-heading {
        margin-bottom: 0.3em
    }

    .field {
        margin-bottom: 0.8em !important;
    }

    input, textarea {
        text-align: center;
        font-size: 1em !important;
    }
}

@media only screen and (max-width: 425px) {

    .form-detail {
        visibility: collapse;
    }
}