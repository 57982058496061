.education {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    background-image: linear-gradient(180deg, rgba(48, 48, 48, 1) -100%, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 1) 90%, rgba(48, 48, 48, 1) 200%);
    text-align: left;
    font-weight: 800;
}

.img-cvut-logo {
    margin-right: 1em;
    height: 2.4em;
    border-radius: 0.5em;
    cursor: pointer;
}

@media only screen and (max-width: 1023px) {
    .education {
        background-color: white;
        background-image: none;
    }
}