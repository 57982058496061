.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #303030;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.loading-content {
    color: white;
    text-align: center;
    position: relative;
}

.loading-content-name {
    font-size: 10vh;
    margin: 0 2em;
    font-weight: 500;
    letter-spacing: 0.05em;
}

@media only screen and (max-width: 1023px) {
    .loading-content-name {
        font-size: 10vw;
    }
}
